<template>
  <div class="login-index">
    <div class="login-title">社 区 生 活 服 务</div>
    <div class="login-content">
      <div class="login-input">
        <img style="width:15px;height:24px" src="../../assets/img/login-phone.png" mode="" />
        <input v-model="form.mobile" placeholder="请输入手机号" maxlength="11" type="tel" placeholder-style="font-size: 16px;
                  color: #b5bcca" />
      </div>
    </div>
    <div class="login-content">
      <div class="login-input login-code">
        <img style="width:21px;height: 22px" src="../../assets/img/login-code.png" mode="" />
        <input v-model="form.code" placeholder="请输入验证码" maxlength="6" type="tel" placeholder-style=" font-size: 16px;
                  color: #b5bcca;" />
        <span style="color: #b5bcca;">|</span>
        <div class="code" :style="{'width': isShowText ? '30%' : '9%'}" @click="getCode">
          {{time}}
        </div>
      </div>
    </div>

    <div class="login-btn" @click="gologin">
      登录
    </div>
  </div>
</template>
  
  <script>
import { getSmsCode, getLogin } from '@/api/login'
export default {
  data() {
    return {
      time: '获取验证码',
      form: {
        mobile: '',
        code: ''
      },
      isShowText: true
    }
  },
  created() {
    this.isShowText = true
    this.time = '获取验证码'
  },
  methods: {
    getCode() {
      if (!this.checkModbile(this.form.mobile)) {
        return
      }
      if (this.isShowText == false) {
        return
      }
      getSmsCode({ mobile: this.form.mobile }).then(res => {
        let s = 60
        this.time = s + 's'
        this.isShowText = false
        let interval = setInterval(() => {
          s--
          this.time = s + 's'
          if (s < 0) {
            this.time = '重新获取'
            this.isShowText = true
            this.isClick = true
            clearInterval(interval)
          }
        }, 1000)
        //提示用户
        // this.$toast({
        //   message,
        //   position: top
        // })

      })
    },

    //使用正则表达式校验电话号码
    checkModbile(mobile) {
      // 校验是否是移动的手机号
      var re = /^((13[4-9])|(15([0-2]|[7-9]))|(18[2|3|4|7|8])|(178)|(147))[\d]{8}$/;
      var result = re.test(mobile);
      if (!result) {
        this.$toast.fail('移动号码格式不正确')
        return;
      }
      return true;
    },

    async gologin() {

      //   this.$router.push({ name: 'home' })
      //   return
      if (!this.checkModbile(this.form.mobile)) {
        return
      }
      const {
        data
      } = await getLogin(this.form)
      if (data.code == 0) {
        var dats = {
          token: data.data
        }
        this.$store.dispatch("AddUser", dats)
        this.$router.push({ name: 'home' })
      } else {
        this.$toast.fail(data.msg)
      }

    },
  }
}
  </script>
  
  <style  scoped>
page {
  height: 100%;
}
.login-index {
  width: 100%;
  height: 100vh;
  background: url("../../assets/img/login.png");
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: 30%;
}
.login-title {
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.login-content {
  color: #e3e9f9;
  width: 80%;
  padding-top: 15%;
  margin: auto;
  font-size: 16px;
}
.login-input {
  /* // border-bottom: 1px solid rgba(255,255,255,.5); */
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  background: #e1eafe;
  box-sizing: border-box;
  padding: 0 15px;
  color: #000;
}
.login-input input {
  width: 80%;
  height: 100%;
  border: none;
  background: none;
}
.login-input img {
  margin-right: 10px;
}

.login-code input {
  width: 50%;
}
.login-code .code {
  width: 45%;
  text-align: center;
  color: #474c53;
}
.login-code {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.login-btn {
  width: 80%;
  margin: auto;
  line-height: 45px;
  border-radius: 30px;
  text-align: center;
  background: #2868ff;
  color: #fff;
  margin-top: 50px;
  font-size: 26px;
}
</style>
  